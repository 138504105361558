<template>
	<div class="container">
		<Header></Header>
		<!-- <div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			当前位置: 十大品牌CNPP > 运动娱乐 > 娱乐活动 > 自动麻将机
		</div> -->
		<div class="i-main wrap clearfix mt-30">
			<div class="article-left articleCt-left fl border-10">

				<div class="articleCt-l1">
					<div class="articleCt-l1-top">
						<span><img src="../../assets/images/articleCt-l1-logo.png" alt="" /></span>{{content.title}}
					</div>
					<div class="articleCt-l1-tagBox">
						
						<span class="articleCt-l1-tag"><a :href="content.schoolWebsite" target="_blank" style="color: #5582b4;">官网查看</a></span>
						<span class="articleCt-l1-tag" v-for="(item,index) in content.labels.split(',')" :key="index">{{item}}</span>
					</div>
					<div class="articleCt-l1-text">
						<p class="" v-html="content.pageDesc"></p>
					</div>

					<div class="articleCt-l1-dw">
						<div class="articleCt-l1-dw-top">
							<span>点赞: {{content.likes}}</span><a><img src="../../assets/images/icon-zan-bai.png"
									alt="" />为TA点赞</a>
						</div>
						<div class="articleCt-l1-dw-picBox">
							<div class="articleCt-l1-dw-pic">
								<img :src="imgUrl+content.images.split(',')[0]" alt="" />
							</div>
							<div class="articleCt-l1-dw-picNum">共{{content.images.split(',').length}}张</div>
						</div>

					</div>


				</div><!-- articleCt-l1 end -->

				<div class="articleCt-leftCon">
					<div class="articleCt-l2">
						<div class="articleCt-l2-tit">基本信息</div>
						<div class="articleCt-l2-box">
							<dl class="articleCt-l2-dl">
								<dt>名称: </dt>
								<dd>{{content.title}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>属性划分: </dt>
								<dd>{{content.educationNature}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>发展: </dt>
								<dd>{{content.schoolMotto}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>适合人群: </dt>
								<dd>{{content.schoolType}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>等级划分: </dt>
								<dd>{{content.educationalLevel}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>创建时间: </dt>
								<dd>{{content.establishmentTime}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>类型: </dt>
								<dd>{{content.schoolCategory}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt>部门: </dt>
								<dd>{{content.schoolAffiliation}}</dd>
							</dl>
							<dl class="articleCt-l2-dl">
								<dt><img src="../../assets/images/icon-address.png" alt="" />地址: </dt>
								<dd>{{content.address}}</dd>
							</dl>
						</div>

					</div><!-- articleCt-l2 end -->
					<div class="articleCt-l3">
						<div class="articleCt-l2-tit">学校简介</div>
						<div class="articleCt-l3-box mt-20" >
							<div class="" v-html="content.content"></div>
						</div>

					</div><!-- articleCt-l2 end -->
					<div class="article-l4 mt-40">
						<div class="article-l4-title">
							<span>免责声明</span>
						</div>
						<p class="article-l4-p">特别声明: 以上作品内容(包括在内的视频、图片或音频)为自媒体与内容原创用户上传并发布，本平台仅提供信息存储空间服务。</p>
						<div class="article-l4-zanbox">
							<div class="article-l4-zan" @click="zanBtn">
								<span></span>
								<p>{{zanNum}}</p>
								<transition name="el-zoom-in-center">
								    <i v-if="numShow">+1</i>
								</transition>
							</div>

						</div>
					</div>

					<div class="article-pinglun mt-40">
						<h3 class="article-pl-tit">评论<span>(0)</span></h3>

						<div class="article-pl-con">

							<div class="article-pl-group">
								<div class="article-pl-img">
									<img src="../../assets/images/art-pl-img_03.png" alt="" />
								</div>
								<div class="article-pl-right">
									<div class="article-pl-box">
										<p>请 <a href="#">登录</a> 后评论</p>
									</div>
								</div>
							</div><!-- article-pl-group end -->

							<!-- <div class="article-pl-group">
								<div class="article-pl-img">
									<img src="../../assets/images/art-pl-img_07.png" alt="" />
								</div>
								<div class="article-pl-right">
									<div class="article-pl-info">
										<h4>蓝鲸</h4>
										<p>作词:王自如?</p>
										<div class="wik-left-m1-bottom clearfix">
											<div class="wik-left-m1-bl fl">4小时前  山东</div>
											<div class="wik-left-m1-br fr">
												<span><img src="../../assets/images/icon-huifu.png" alt="" />回复</span>
												<span><img src="../../assets/images/icon-zan.png" alt="" />2</span>
											</div>
										</div>
									</div>
									
								</div>
							</div> -->

							<!-- <div class="article-pl-group">
								<div class="article-pl-img">
									<img src="../../assets/images/art-pl-img_10.png" alt="" />
								</div>
								<div class="article-pl-right">
									<div class="article-pl-info">
										<h4>海滨初夏夜</h4>
										<p>不知道什么样得人才会卖给了，这文化也太让人无法接受了</p>
										<div class="wik-left-m1-bottom clearfix">
											<div class="wik-left-m1-bl fl">5小时前  天津</div>
											<div class="wik-left-m1-br fr">
												<span><img src="../../assets/images/icon-huifu.png" alt="" />回复</span>
												<span><img src="../../assets/images/icon-zan.png" alt="" />1</span>
											</div>
										</div>
									</div>
									
								</div>
							</div> -->

						</div>

					</div><!-- article-pinglun end -->
				</div><!-- articleCt-leftCon end -->
			</div><!-- article-left end -->


			<div class="article-right fr">
				<div class="article-right-tj">
					<div class="wik-right-tt-tit">相关推荐</div>

					<ul class="wik-right-tj-list mt-20">
						<li v-for="(item,i) in 5" :key="i">
							<a href="#">
								<div class="wik-right-tj-img">
									<img src="../../assets/images/art-r-tjImg_03.png" alt="" />
								</div>
								<div class="wik-right-tj-txt">
									<h4>董明珠开个年会,卖了 60个亿</h4>
									<p>#房子大咖</p>
								</div>
							</a>
						</li>
					</ul>

				</div><!-- article-right-tj end -->
				<div class="wik-right-tt mt-15">
					<div class="wik-right-tt-tit">热搜榜</div>

					<ul class="wik-right-tt-list">
						<li v-for="(item,h) in 10" :key="h">
							<i>{{h+1}}</i>
							<div class="wik-right-tt-text">
								<a href="#">腾讯第二季度营收1492亿元 同比 </a><span class="wik-right-tt-hot" v-if='h == 0'>热</span>
							</div>
						</li>
					</ul>
				</div><!-- wik-right-tt end -->


			</div><!-- article-right end -->
		</div><!-- i-main end  -->


		<div class="i-m9-wrap mt-40">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon" />
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->

		<Footer></Footer>

		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>


	</div>
</template>

<script>
	import {
		jingdian,
		yiyuan,
		mingren,
		xuexiao,
		bowuguan
	} from "../../api/citiao.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "citiao",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				conDate: true, //控制404页面显示
				// imgUrl:'http://1.94.30.25:8088',
				content:{},
				leftList: [],
				zanNum: 0,
				numShow:false,
				id: "",
				type: "",
				pageTitle:'品牌之家',
			};
		},

		created() {

			const id = this.$route.query.id; // 获取参数值
			const type = this.$route.query.type // 获取参数值
			console.log('接收id', id); // 输出参数品牌id
			console.log('接收type', type); // 输出参数行业id
			this.id = Number(id)+9999
			this.type = type
			// 0学校,1医院,2景点,3博物馆,4名人
			if (type == 0) {
				this.getXuexiao()
			} else if (type == 1) {
				this.getYiyuan()
			} else if (type == 2) {
				this.getJingdian()
			} else if (type == 3) {
				this.getBowuguan()
			} else {
				this.getMingren()
			}
		},

		computed: {},

		mounted() {},

		methods: {
			initPage() {
				this.getJingdian()
				this.getYiyuan()
				this.getMingren()
				this.getXuexiao()
				this.getBowuguan()
			},

			//点赞
			zanBtn(){
				this.zanNum = this.zanNum+1
				this.numShow = true
				var that = this
				setTimeout(function(){
					// alert(1111)
					that.numShow = false
				},2000)
			},


			getXuexiao() {
				xuexiao(this.id).then(res => {
					console.log(res.data.data);
					this.content = res.data.data
					this.zanNum = res.data.data.likes
				})
			},

			getYiyuan() {
				yiyuan(this.id).then(res => {
					console.log(res.data.data);
					this.content = res.data.data
					this.zanNum = res.data.data.likes
				})
			},

			getMingren() {
				mingren(this.id).then(res => {
					console.log(res.data.data);
					this.content = res.data.data
					this.zanNum = res.data.data.likes
				})
			},

			getJingdian() {
				jingdian(this.id).then(res => {
					console.log(res.data.data);
					this.content = res.data.data
					this.zanNum = res.data.data.likes
				})
			},
			getBowuguan() {
				bowuguan(this.id).then(res => {
					console.log(res.data.data);
					this.content = res.data.data
					this.zanNum = res.data.data.likes
				})
			},




		},
	}
</script>

<style scoped>
	@import "../../assets/css/article.css";
	.articleCt-l1-dw-pic,
	.articleCt-l1-dw-pic img{
		width: 100%;
		height: 100%;
	}
</style>